import {AppManifestBuilder} from '@wix/yoshi-flow-editor'
import {EVENTS_WIDGET_ID} from '@wix/wix-events-commons-statics'
import {getSettingsUrl, openSettings} from '../services/sdk'
import {ARTICLES} from '../../components/widget/Settings/constants'
import {TAB} from '../../commons/constants/navigation'
import {EVENTS_APP_DEF_ID} from '../constants'

export const widgetGfppBuilder = ({t, experiments, editorSdk, appToken}) => async (
  appManifestBuilder: AppManifestBuilder,
) => {
  const newWidgetSettingsGfpp = experiments['specs.events.ui.NewWidgetSettingsGFPP'].toString()
  const isNewGfppButtons = ['display', 'show'].includes(newWidgetSettingsGfpp)

  if (isNewGfppButtons) {
    const appData = await editorSdk.document.tpa.app.getDataByAppDefId(appToken, EVENTS_APP_DEF_ID)
    const widgetSettingsUrl = getSettingsUrl(appData, EVENTS_WIDGET_ID)

    return appManifestBuilder.configureWidget(EVENTS_WIDGET_ID, widgetBuilder =>
      widgetBuilder
        .gfpp()
        .set('mainAction2', {
          label: t(
            newWidgetSettingsGfpp === 'display'
              ? 'gfppWidgetSettingsButtonDisplayEvents'
              : 'gfppWidgetSettingsButtonShowEvents',
          ),
          onClick: event => {
            openSettings(
              editorSdk,
              appToken,
              {
                title: t('widgetSettingsTitle'),
                url: widgetSettingsUrl,
                componentRef: event.detail.componentRef,
                initialData: {
                  tab: 'events',
                  myEventsText: newWidgetSettingsGfpp === 'display' ? 'display' : 'show',
                },
                helpId: ARTICLES[TAB.MY_EVENTS],
              },
              {preloader: true},
            )
          },
        })
        .set('settings', {
          onClick: event =>
            openSettings(
              editorSdk,
              appToken,
              {
                title: t('widgetSettingsTitle'),
                url: widgetSettingsUrl,
                componentRef: event.detail.componentRef,
                initialData: {
                  myEventsText: newWidgetSettingsGfpp === 'display' ? 'display' : 'show',
                },
                helpId: ARTICLES[TAB.MAIN],
              },
              {preloader: true},
            ),
        }),
    )
  }

  return appManifestBuilder
}
